<template>
  <div id="defaultId1" class="pb20">
    <card id="g8a6de" class="mb10" :isShowLabel="true" label="Related Companies" v-if="tableData.length > 0">
      <template id="c4f7b9" slot="right_view">
        <cardIcon id="g86368" v-model="isShowCard" :isShowMapIcon="false" @change-value="getShowIcon"
          @show-map="showMapCard">
        </cardIcon>
      </template>
      <!-- vague -->
      <div id="cca679" v-show="activeIcon == 'table'" class="uM2fl1R2 table_X ulpHVGJ">
        <el-table style="max-height: 800px; overflow-y: auto" empty-text="No Data" id="c59ffa" class="uvnONjL u6cmzvV table_height_30 table_font_size_14 table_contentHeight_40" :data="tableData">
          <el-table-column id="c54c13" class="uMxWZAr" prop="name" label="Name" width="550" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.relations=='0'">
                {{ scope.row.name }}
              </span>
              <span v-else class="company_link" @click="setid(scope.row)" style="">
                {{ scope.row.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column id="c8fa16" class="uXGOdre" prop="country" label="Country" show-overflow-tooltip>
          </el-table-column>
          <el-table-column id="c4a5d7" class="u5UjLPj" prop="location" label="Location" show-overflow-tooltip>
          </el-table-column>
          <el-table-column id="c607a0" class="uWpGliS" prop="type" label="Type" show-overflow-tooltip>
          </el-table-column>
          <el-table-column id="c9c40f" class="u1wvOcS" prop="relations" label="Relations" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div id="caa693" class="uZgkgLm ukwExah show_AllHeight" v-if="this.page.total > this.page.pageSize">
          <span id="c85944" class="u144f9 uSoOFk1 show_All" @click="ShowAll(true)">Show All
          </span>
        </div>
      </div>

      <div v-show="activeIcon == 'computer'" class="echartsBtnspiv">
        <div class="echartsBtns">
          <el-button icon="el-icon-plus" :disabled="level >= 3" @click="changeLevel('plus')" />
          <el-button icon="el-icon-minus" :disabled="level <= 1" @click="changeLevel('minus')" />
        </div>
        <div id="g08e9f">
          <div id="idsLwvVN" class="ulDmLg9" alt ref="trendChart"></div>
        </div>
      </div>
    </card>
    <card id="idMcECxQ" v-show="isShowCard" class="u2vZuCk mb10" :isShowLabel="true" label="Map Distribution">
      <template id="c3e0dc" slot="right_view">
        <div id="c2b8e2" @click="isShowCard = !isShowCard">
          <svg id="idAMj6dg" class="icon" t="1718863867278" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="4648" width="1.38em" height="1.38em">
            <path id="idiUOtRv"
              d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
              fill="#1A2332" p-id="4649"></path>
          </svg>
        </div>
      </template>
      <div id="gc9bed" class="ucVKeMV">
        <!-- 搜索框部分 -->
        <div id="g69925" class="input_X uf67d0">
          <div id="ge561c" class="ubcc21">
            <el-input autocomplete="off" id="idTCQqRC" v-model="input" class="uomvXz5"
              placeholder="Search for Company Name">
            </el-input>
            <div id="gb3ccf" class="uded6c">
              <svg id="idKTA4jv" class="icon" t="1718870137090" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4923" width="2.84em" height="2.84em">
                <path id="idVqro2A"
                  d="M863.38 825.09L714.17 675.88c48.01-57.12 74.18-128.58 74.18-204.08 0-84.89-33.06-164.7-93.08-224.72C635.24 187.05 555.44 154 470.55 154c-84.89 0-164.7 33.06-224.72 93.08s-93.08 139.83-93.08 224.72 33.06 164.7 93.08 224.72 139.83 93.08 224.72 93.08c76.22 0 148.33-26.67 205.72-75.56l149.08 149.08a26.805 26.805 0 0 0 19.01 7.88c6.88 0 13.76-2.63 19.01-7.88a26.805 26.805 0 0 0 7.88-19.01c0-6.89-2.62-13.77-7.87-19.02zM206.53 471.8c0-145.58 118.44-264.02 264.02-264.02 145.58 0 264.02 118.44 264.02 264.02S616.13 735.82 470.55 735.82c-145.58 0-264.02-118.44-264.02-264.02z"
                  fill="#ffffff" p-id="4924"></path>
              </svg>
            </div>
          </div>
          <div id="gda0a3" class="table_X u3eec5">
            <el-select id="idulvBMK" v-model="valuecountry" clearable class="u5ieVg3"
              placeholder="Select a Company or Region" @change="SelctchangEcharts()">
              <el-option id="idwLfbO2" v-for="item in tableData" :key="item.id3a" :label="item.name" :value="item.id3a">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 地图部分 -->
        <div id="idlERtoC" class="urwrm7d ulFepxe" ref="worldMap"></div>
      </div>
    </card>
    <card id="cef301" class="mb10" :isShowLabel="true" label="Branch Offices" v-if="BranchData.length > 0">
      <template id="cea077" class="utEN1v9" slot="right_view">
        <cardIcon id="c5c4db" v-model="isShowCard2" class="uwvCaCu" :isShowMapIcon="true" @change-value="getShowIcon2"
          @show-map="showMapCard2">
        </cardIcon>
      </template>
      <div id="ccaac5" v-if="activeIcon2 == 'table'" class="uM2fl1R2 table_X ulpHVGJ">
        <el-table style="max-height: 800px; overflow-y: auto" id="cbc0bc" class="uvnONjL uzrM6EH table_height_30 table_font_size_14 table_contentHeight_40" :data="BranchData" empty-text="No Data">
          <el-table-column id="c54c13" class="uMxWZAr" prop="name" label="Name" width="550" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.relations=='0'">{{ scope.row.name }}</span>
              <span v-else class="company_link" @click="setid(scope.row)" style="">
                {{ scope.row.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column id="c8fa16" class="uXGOdre" prop="country" label="Country" show-overflow-tooltip>
          </el-table-column>
          <el-table-column id="c4a5d7" class="u5UjLPj" prop="location" label="Location" show-overflow-tooltip>
          </el-table-column>
          <el-table-column id="c607a0" class="uWpGliS" prop="type" label="Type" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div id="c206da" class="uZgkgLm ukwExah show_AllHeight" v-if="this.pageTwo.total > this.pageTwo.pageSize">
          <span id="c1664e" class="u144f9 uSoOFk1 show_All u5xXf9s" @click="ShowAll(false)">Show All
          </span>
        </div>
      </div>
      <div id="c3cffb" v-show="activeIcon2 == 'computer'" class="echartsBtnspiv">
        <div class="echartsBtns">
          <el-button icon="el-icon-plus" :disabled="level2 >= 3" @click="changeLevel2('plus')" />
          <el-button icon="el-icon-minus" :disabled="level2 <= 1" @click="changeLevel2('minus')" />
        </div>
        <div id="g08e9f">
          <div id="idsLwvVN" class="ulDmLg9" alt ref="ownership2"></div>
        </div>
      </div>
    </card>
    <card id="c00c90" v-show="isShowCard2" class="u2vZuCk" :isShowLabel="true" label="Map Distribution">
      <template id="cb90ca" class="unTAK6B" slot="right_view">
        <div id="c369bc" @click="isShowCard2 = !isShowCard2" class="uQV3InT">
          <svg id="c170f1" class="icon uNUXkuV" t="1718863867278" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="4648" width="1.38em" height="1.38em">
            <path id="c4efa6" class="ulqVmWu"
              d="M547.4 512l278.2-278.2c9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0L512 476.6 233.8 198.4c-9.8-9.8-25.6-9.8-35.4 0-9.8 9.8-9.8 25.6 0 35.4L476.6 512 198.4 790.2c-9.8 9.8-9.8 25.6 0 35.4 4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3L512 547.4l278.2 278.2c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L547.4 512z"
              fill="#1A2332" p-id="4649"></path>
          </svg>
        </div>
      </template>
      <div id="cb9c9d" class="ucVKeMV">
        <!-- 搜索框部分 -->
        <div id="c30b0d" class="input_X uf67d0">
          <div id="cbc332" class="ubcc21">
            <el-input autocomplete="off" id="c767dc" v-model="input" class="uomvXz5"
              placeholder="Search for Company Name">
            </el-input>
            <div id="c3a756" class="uded6c">
              <svg id="c6e148" class="icon u6S8jzd" t="1718870137090" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="4923" width="2.84em" height="2.84em">
                <path id="c817f6" class="uRENP4v"
                  d="M863.38 825.09L714.17 675.88c48.01-57.12 74.18-128.58 74.18-204.08 0-84.89-33.06-164.7-93.08-224.72C635.24 187.05 555.44 154 470.55 154c-84.89 0-164.7 33.06-224.72 93.08s-93.08 139.83-93.08 224.72 33.06 164.7 93.08 224.72 139.83 93.08 224.72 93.08c76.22 0 148.33-26.67 205.72-75.56l149.08 149.08a26.805 26.805 0 0 0 19.01 7.88c6.88 0 13.76-2.63 19.01-7.88a26.805 26.805 0 0 0 7.88-19.01c0-6.89-2.62-13.77-7.87-19.02zM206.53 471.8c0-145.58 118.44-264.02 264.02-264.02 145.58 0 264.02 118.44 264.02 264.02S616.13 735.82 470.55 735.82c-145.58 0-264.02-118.44-264.02-264.02z"
                  fill="#ffffff" p-id="4924"></path>
              </svg>
            </div>
          </div>
          <div id="cf2379" class="table_X u3eec5">
            <el-select id="ca8c0f" v-model="value" clearable class="u5ieVg3" placeholder="Select a Company or Region"
              @change="SelctchangEchartstwo()">
              <el-option id="c8fdf4" v-for="item in BranchData" class="ukrQNmg" :key="item.id3a" :label="item.name"
                :value="item.id3a">
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 地图部分 -->
        <div id="c5b04d" class="urwrm7d ulFepxe" ref="worldMap2"></div>
      </div>
    </card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
    <!-- <PermissionDialog></PermissionDialog> -->
  </div>
</template>
<script>
// interfaceCode
//importStart

import card from "../../../../components/gui_card/gui_card.vue";
import cardIcon from "../../../../components/cardIcon/index.vue";
import ReLogin from "../../../../components/ReLogin.vue";
import { formatterTxt, formatterTxtTips, formatList } from "./utils";
//importEnd
import {
  getCompanies,
  getCompanyBranch,
  getRelatedSummary,
  getCompanyRelatedChart,
  getCompanyBranchChart,
  getPartPrivilege,
} from "@/api/companyApi";
import PermissionDialog from "../../../../components/power/PermissionDialog.vue";


export default {
  name: "",
  props: {},
  mounted() {
    this.getlist();
    // this.getPartPrivilege();
    this.getCompanyBranchList();
    // this.renderWorldMap();
    this.getShareholderSummaryList();
    this.echartslist();
    this.echartslisttwo();
  },

  components: {
    cardIcon,
    card,
    PermissionDialog,
    ReLogin,
  },

  data() {
    return {
      reLoginVis: false,
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      powerArr: [],
      pageTwo: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      ownershipEchart: "",
      // ownershipEchart2
      ownershipEchart2: "",
      chartData: null,
      echartsdate: [],
      echartsdateTwo: [],
      level: 1,
      level1: 1,
      level2: 1,
      level3: 1,
      comIcon1: "image://" + require("@/assets/img/echarts/comIcon1.png"),
      comIcon2: "image://" + require("@/assets/img/echarts/WechatIMG31.png"),
      comIcon3: "image://" + require("@/assets/img/echarts/comIcon3.png"),
      comIcon4: "image://" + require("@/assets/img/echarts/comIcon4.png"),
      comIcon5: "image://" + require("@/assets/img/echarts/comIcon5.png"),
      // isShowCard
      isShowCard: false,
      // activeIcon
      activeIcon: "table",
      // tableData
      tableData: [],
      tableDatamap: [],
      BranchData: [],
      BranchDatamap: [],
      valuecountry: "",
      // input
      input: "",
      // value
      value: "",
      // options
      options: [],
      // isShowCard2
      isShowCard2: false,
      // activeIcon2
      activeIcon2: "table",
    };
  },
  methods: {

    setid(row) {
      let companyCountryEn = "";

      let routeData = this.$router.resolve({
        path:
          "/index/company/detals/" +
          row.id3a +
          "/background/snapshot?id3a=" +
          row.id3a +
          "&companyCountry=" +
          "" +
          "&companyCountryEn=" +
          companyCountryEn,
      });

      window.open(routeData.href, "_blank");
    },
    SelctchangEcharts() {
      if (this.valuecountry) {
        this.tableDatamap = this.tableData.filter(
          (item) => item.id3a == this.valuecountry
        );
      } else {
        this.tableDatamap = this.tableData;
      }

      this.$nextTick(() => this.renderWorldMap());
    },
    SelctchangEchartstwo() {
      if (this.value) {
        this.BranchDatamap = this.BranchData.filter(
          (item) => item.id3a == this.value
        );
      } else {
        this.BranchDatamap = this.BranchData;
      }
      this.$nextTick(() => this.renderWorldMap2());
    },
    getShowIcon(value) {
      this.activeIcon = value;
      if (value == 'computer') {
        this.$nextTick(() => this.showOwnershipEcahrt())
      }
      // ;
      // if (value == "computer") {

      //   if (this.powerArr[0]?.hasPrivilege) {
      //     this.activeIcon = value;


      //   }
      //   else {
      //   this.activeIcon2 = 'table'
      //     this.$bus.$emit("show-permission-dialog", "您没有权限访问该功能！");
      //   }
      // }else{
      //   this.activeIcon = value;
      // }
    }, // showMapCard
    getShowIcon2(value) {
      this.activeIcon2 = value;
      if (value == 'computer') {
        this.$nextTick(() => this.showOwnershipEcahrt2());
      }

      // if (value == "computer") {
      //   if (this.powerArr[0]?.hasPrivilege) {

      //   } else {
      //     this.activeIcon2 = 'table'
      //     this.$bus.$emit("show-permission-dialog", "您没有权限访问该功能！");
      //   }
      // }
      // else{
      //   this.activeIcon2 = value;
      // }

    },
    showMapCard(map) {
      this.isShowCard = map;
      this.$nextTick(() => {
        this.renderWorldMap();
      });
      // if (this.powerArr[1]?.hasPrivilege) {

      // }else {
      //   this.$bus.$emit("show-permission-dialog", "您没有权限访问该功能！");
      // }
    }, // zoomIn
    showMapCard2(map) {
      this.isShowCard2 = map;

      this.$nextTick(() => {
        this.renderWorldMap2();
      });


    },
    // renderWorldMap
    renderWorldMap() {
      let mapdata = [];
      let mapCountry = []
      this.tableDatamap.map((item) => {
        mapdata.push({
          name: item.platName,
          value: [item.lng, item.lat],

        });
        mapCountry.push(
          item.country
        )
      });
   
      if (!this.echartsBox) {
        this.echartsBox = this.$echarts.init(this.$refs.worldMap);
      }

      let series = [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,

          rippleEffect: {
            period: 4,
            brushType: "fill",
            scale: 0,
          },

          label: {
            normal: {
              show: true,
              position: "right",
              offset: [5, 0],
              offset: [5, 0],
              formatter: "{b}",
              padding: [5, 20, 5, 20],
              borderWidth: 1,
              borderColor: "#1290C9",
              borderRadius: 15,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            },

            emphasis: {
              show: true,
            },
          },

          symbol: "image://" + require("@/assets/images/地址.png"),
          symbolSize: [16, 16],

          itemStyle: {
            normal: {
              show: false,
            },
          },

          data: mapdata,
        },
      ];

      const option = {
        visualMap: {
          min: 0,
          max: 10000,
          show: false,
          calculable: true,
          color: ["#ff9500"],

          textStyle: {
            color: "#fff",
          },
        },

        geo: {
          map: "world",
          zoom: 1.5,

          label: {
            emphasis: {
              show: true,
              color: "#fff",
            },
          },

          roam: true,
          layoutCenter: ["50%", "50%"],
          layoutSize: "180%",

          itemStyle: {
            normal: {
              areaColor: "#edf3f3",
              borderColor: "#9d9f9f",
            },

            emphasis: {
              areaColor: "#e7eaed ",
            },
          },
          regions: mapCountry.map(country => ({
            name: country,
            itemStyle: {
              areaColor: '#1290C9', // 高亮颜色
            },
          })),
        },


        series: series,
      };

      this.echartsBox.setOption(option);
    }, // renderWorldMap2
    renderWorldMap2() {
      let mapdata = [];
      let mapCountry = []
      this.BranchDatamap.map((item) => {
        mapdata.push({
          name: item.name,
          value: [item.lng, item.lat],
        });
        mapCountry.push(
          item.platName
        )
      });
      if (!this.echartsBox2) {
        this.echartsBox2 = this.$echarts.init(this.$refs.worldMap2);
      }

      let series = [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,

          rippleEffect: {
            period: 4,
            brushType: "fill",
            scale: 0,
          },

          label: {
            normal: {
              show: true,
              position: "right",
              offset: [5, 0],
              offset: [5, 0],
              formatter: "{b}",
              padding: [5, 20, 5, 20],
              borderWidth: 1,
              borderColor: "#1290C9",
              borderRadius: 15,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            },

            emphasis: {
              show: true,
            },
          },

          symbol: "image://" + require("@/assets/images/地址.png"),
          symbolSize: [16, 16],

          itemStyle: {
            normal: {
              show: false,
            },
          },

          data: mapdata,
        },
      ];

      const option = {
        visualMap: {
          min: 0,
          max: 10000,
          show: false,
          calculable: true,
          color: ["#ff9500"],

          textStyle: {
            color: "#fff",
          },
        },

        geo: {
          map: "world",
          zoom: 1.5,

          label: {
            emphasis: {
              show: true,
              color: "#fff",
            },
          },

          roam: true,
          layoutCenter: ["50%", "50%"],
          layoutSize: "180%",

          itemStyle: {
            normal: {
              areaColor: "#edf3f3",
              borderColor: "#9d9f9f",
            },

            emphasis: {
              areaColor: "#e7eaed ",
            },
          },
          regions: mapCountry.map(country => ({
            name: country,
            itemStyle: {
              areaColor: '#1290C9', // 高亮颜色
            },
          })),
        },

        series: series,
      };

      this.echartsBox2.setOption(option);
    },

    // echarts1

    add(list) {
      list.forEach(async (item) => {
        if (!item.children.length) {
          if (item.code) {
            const params = {
              id3a: item.code,
              page: "1",
              pageSize: "15",
            };
            const res = await getCompanyRelatedChart(params);
            let ecahrtsdata = res;
            ecahrtsdata = JSON.parse(
              JSON.stringify(ecahrtsdata).replace(/subsetList/g, "children")
            );
            if (ecahrtsdata && ecahrtsdata.code) {
              this.loading = false;
              this.getList(
                this.echartsdate.children,
                item.code,
                ecahrtsdata.data.children
              );
              this.setEchartList();
              this.$nextTick(() => this.showOwnershipEcahrt());
            } else {
            }
          }
        } else {
          this.add(item.children);
        }
      });
    },
    changeLevel(type) {
      if (type === "plus") {
        this.level++;
        this.level1++;
        this.level1 > 4 ? (this.level1 = 4) : this.level11;
        
        // 点加号按钮寻找当前数组的最外层children
        let that = this;
        if (this.level >= 2) {
        
          if (this.level1 < 4) {
            that.add(that.echartsdate.children);
          }
        }
      }

      if (type === "minus") this.level--;
      this.$nextTick(() => this.showOwnershipEcahrt());
    },
    // 递归获取数组
    getList(list, code, newVal) {
      list.forEach((item, index) => {
        if (list[index].code === code) {
          list[index].children = newVal;
        } else if (list[index].children && list[index].children.length > 0) {
          this.getList(list[index].children, code, newVal);
        }
      });
    },
    async echartslist() {
      const params = {
        id3a: this.$route.query.id3a,
        page: "1",
        pageSize: "15",
      };
      const res = await getCompanyRelatedChart(params);
      const ecahrtsdata = res;
      if (ecahrtsdata && ecahrtsdata.code) {
        this.echartsdate = ecahrtsdata.data;
        this.setEchartList();
        this.$nextTick(() => this.showOwnershipEcahrt());
      } else {
        this.loading = false;
      }
    },
    setEchartList(echartsdate = this.echartsdate) {
      const echartData = echartsdate.subsetList || []; // 股东list

      const data = {
        name: this.echartsdate.name,
        nameEn: this.echartsdate.nameEn,
        code: this.companyAaaid,
        children: [],
      };
      const childrenList = formatList(
        echartData,
        this.companyAaaid,
        this.companyAaaid
      );
      data.children = childrenList;
      if (childrenList && childrenList.length > 0) {
        this.getLevel2Children(data, this.companyAaaid);
      } else {
        this.echartData = data;
      }
    },
    getLevel2Children(vals = data, aaaid) {
      const echartData = vals;

      echartData.children.map((item, index) => {
        if (item.isMax == aaaid) {
          const echartData = item.subsetList || [];
          const subsetList = formatList(
            echartData,
            this.companyAaaid,
            this.companyAaaid
          );
          item.subsetList = subsetList;
        }
      });
      this.echartsdate = echartData;
    },
    // showOwnershipEcahrt
    async showOwnershipEcahrt() {
      this.activeIcon = "computer";

      if (!this.ownershipEchart) {
        this.ownershipEchart = this.$echarts.init(this.$refs.trendChart);
        await this.$nextTick();
      }
      const that = this;
      let leval = that.level;
      const initialTreeDepth = leval;
      that.echartsdate = JSON.parse(
        JSON.stringify(that.echartsdate).replace(/subsetList/g, "children")
      );
      let data = that.echartsdate;
      const symbol = (data) => {
        if (`${data.invtypecode}` === "3") {
          // 外国公司
          return this.comIcon3;
        }
        if (`${data.invtypecode}` === "5") {
          // 国营企业
          return this.comIcon5;
        }

        if (`${data.code}` === this.$route.query.id3a) {
          return this.comIcon4;
        }
        if (data.type == "2") {
          // 个人
          return this.comIcon2;
        }
        return this.comIcon1;
      };
      const option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          fontSize: 12,
          formatter: (params) => formatterTxt(params),
        },
        series: [
          {
            type: "tree",
            data: [data],
            top: "18%",
            bottom: "14%",
            // roam: true,
            clickable: true,

            layout: "radial",
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: "ancestor",
            },
            orient: "TB",
            label: {
              fontSize: 9,
              formatter: (params) => formatterTxt(params),
              rotate: 360,
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => formatterTxt(params),
                rotate: 360,
              },
            },
          },
        ],
      };

      this.ownershipEchart.setOption(option);
    },
    getShareholderSummaryList() {
      getRelatedSummary({ id3a: this.$route.query.id3a }).then((res) => {
        if (res.code == 200) {
          let manManagementSummary = [
            {
              name: "Total",
              id: "Total",
              num: res.data.total,
            },
            {
              name: "Entities",
              id: "Entities",
              num: res.data.entities,
            },
            {
              name: "Individuals",
              id: "Individuals",
              num: res.data.persons,
            },
            {
              name: "Countries",
              id: "Countries",
              num: res.data.countries,
            },
            {
              name: "Branches",
              id: "Branches",
              num: res.data.branches,
            },
            {
              name: "Related Companies",
              id: "Related Companies",
              num: res.data.relatedCompanies,
            },
          ];

          this.$emit("UpdatamarkTotalList", manManagementSummary);
        } else {
          this.$emit("UpdatamarkTotalList", []);
        }
      });
    },
    ShowAll(check) {
      if (check) {
        if (this.page.total > this.page.pageSize) {
          this.page.pageSize = this.page.pageSize + 10;
          this.getlist();
        }
      } else {
        if (this.pageTwo.total > this.pageTwo.pageSize) {
          this.pageTwo.pageSize = this.pageTwo.pageSize + 10;
          this.getCompanyBranchList();
        }
      }
    },
    getCompanyBranchList() {
      const id3a = this.$route.query.id3a;
      getCompanyBranch({
        id3a: id3a,
        page: this.page.pageNo,
        pageSize: this.pageTwo.pageSize,
      }).then((Res) => {
        if (Res.code == 200) {
          this.BranchData = Res.data.rows;
          this.BranchDatamap = Res.data.rows;
          this.pageTwo.total = Res.data.total;

          //   this.BranchData.map(item=>{
          //     that.options.push({
          //         value:item.country+'1',
          //         label:item.country
          //     })
          //   })
        } else {
          this.BranchData = [];
        }
      });
    },
    getlist() {
      const id3a = this.$route.query.id3a;
      getCompanies({
        id3a: id3a,
        page: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((Res) => {
        if (Res.code == 200) {
          this.tableData = Res.data.rows;
          this.tableDatamap = Res.data.rows;
          this.page.total = Res.data.total;
          let that = this;

          // this.tableData.map((item) => {
          //   that.options.push({
          //     value: item.country + "1",
          //     label: item.country,
          //   });
          // });
          // this.$nextTick(() => {
          //   this.renderWorldMap();
          // });
        } else {
          this.tableData = [];
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
    },

    // showOwnershipEcahrt2
    async showOwnershipEcahrt2() {
      this.activeIcon2 = "computer";
      // await this.$nextTick();

      if (!this.ownershipEchart2) {
        debugger;
        this.ownershipEchart2 = this.$echarts.init(this.$refs.ownership2);
        await this.$nextTick();
      }
      const that = this;
      let leval = that.level2;
      const initialTreeDepth = leval;
      that.echartsdateTwo = JSON.parse(
        JSON.stringify(that.echartsdateTwo).replace(/subsetList/g, "children")
      );
      let data = that.echartsdateTwo;
      const symbol = (data) => {
        if (`${data.invtypecode}` === "3") {
          // 外国公司
          return this.comIcon3;
        }
        if (`${data.invtypecode}` === "5") {
          // 国营企业
          return this.comIcon5;
        }

        if (`${data.code}` === this.$route.query.id3a) {
          return this.comIcon4;
        }
        if (data.type == "2") {
          // 个人
          return this.comIcon2;
        }
        return this.comIcon1;
      };
      const option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
          fontSize: 12,
          formatter: (params) => formatterTxt(params),
        },
        series: [
          {
            type: "tree",
            data: [data],
            top: "18%",
            bottom: "14%",
            // roam: true,
            clickable: true,

            layout: "radial",
            symbol: (value, param) => symbol(param.data),
            symbolSize: 30,
            initialTreeDepth,
            animationDurationUpdate: 750,
            emphasis: {
              focus: "ancestor",
            },
            orient: "TB",
            label: {
              fontSize: 9,
              formatter: (params) => formatterTxt(params),
              rotate: 360,
            },
            leaves: {
              label: {
                fontSize: 9,
                formatter: (params) => formatterTxt(params),
                rotate: 360,
              },
            },
          },
        ],
      };

      this.ownershipEchart2.setOption(option);
    },
    async echartslisttwo() {
      const params = {
        id3a: this.$route.query.id3a,
        page: "1",
        pageSize: "15",
      };
      const res = await getCompanyBranchChart(params);
      const ecahrtsdata = res;
      if (ecahrtsdata && ecahrtsdata.code) {
        this.echartsdateTwo = ecahrtsdata.data;

        this.setEchartListtwo();
        this.$nextTick(() => this.showOwnershipEcahrt2());
      } else {
        this.loading = false;
      }
    },
    setEchartListtwo(echartsdate = this.echartsdateTwo) {

      const echartData = echartsdate.subsetList || []; // 股东list

      const data = {
        name: this.echartsdateTwo.name,
        nameEn: this.echartsdateTwo.nameEn,
        code: this.companyAaaid,
        children: [],
      };
      const childrenList = formatList(
        echartData,
        this.companyAaaid,
        this.companyAaaid
      );
      data.children = childrenList;
      if (childrenList && childrenList.length > 0) {
        this.getLevel2ChildrenTwo(data, this.companyAaaid);
      } else {
       

        this.echartDataTwo = data;
      }
    },
    getLevel2ChildrenTwo(vals = data, aaaid) {
      const echartData = vals;

      echartData.children.map((item, index) => {
        if (item.isMax == aaaid) {
          const echartData = item.subsetList || [];
          const subsetList = formatList(
            echartData,
            this.companyAaaid,
            this.companyAaaid
          );
          item.subsetList = subsetList;
        }
      });
      this.echartsdateTwo = echartData;
    },
    changeLevel2(type) {

      if (type === "plus") {
        this.level2++;
        this.level3++;
        this.level3 > 4 ? (this.level3 = 4) : this.level11;
        // 点加号按钮寻找当前数组的最外层children
        let that = this;
        if (this.level2 >= 2) {
          if (this.level3 < 4) {

            that.add2(that.echartsdateTwo.children);
          }
        }
      }

      if (type === "minus") this.level2--;
      this.$nextTick(() => this.showOwnershipEcahrt2());
    },
    add2(list) {
      list.forEach(async (item) => {
        if (!item.children.length) {
          if (item.code) {
            const params = {
              id3a: item.code,
              page: "1",
              pageSize: "15",
            };
            const res = await getCompanyBranchChart(params);
            let ecahrtsdata = res;
            ecahrtsdata = JSON.parse(
              JSON.stringify(ecahrtsdata).replace(/subsetList/g, "children")
            );
            if (ecahrtsdata && ecahrtsdata.code) {
              this.loading = false;
              this.getList(
                this.echartsdateTwo.children,
                item.code,
                ecahrtsdata.data.children
              );
              this.setEchartListtwo();
              this.$nextTick(() => this.showOwnershipEcahrt2());
            } else {
            }
          }
        } else {

          this.add2(item.children);
        }
      });
    },

    // roamMap
    roamMap(flag, ownership) {
      const currentZoom = ownership.getOption().series[0].zoom;
      let increaseAmplitude = 1.2;

      if (flag === 1) {
        increaseAmplitude = 0.8;
      }

      ownership.setOption({
        series: [
          {
            zoom: currentZoom * increaseAmplitude,
          },
        ],
      });
    }, // getShowIcon


  },
  computed: {},
};
</script>
<style>
.ukwExah {
  height: 4.571428571428571em;
  line-height: 4.571428571428571em;
  text-align: center;
  /* font-size: 1.4285714285714286em; */
  color: #1290c9;
}

.uPVggJ1 {
  display: flex;
}

.ud36a5 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  box-shadow: 0em 0em 0.2857142857142857em 0em rgba(0, 0, 0, 0.1);
  border-radius: 0.2857142857142857em;
}

.u7d76d {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  box-shadow: 0em 0em 0.2857142857142857em 0em rgba(0, 0, 0, 0.1);
  border-radius: 0.2857142857142857em;
  margin-top: 0.7142857142857143em;
}

.u6600b {
  height: 56.857142857142854em;
  width: 0;
  flex: auto;
}

.uf67d0 {
  display: flex;
  height: 3.5714285714285716em;
}

.ubcc21 {
  display: flex;
}

.uomvXz5 {
  width: 24.285714285714285em;
  border-radius: 0.2857142857142857em 0em 0em 0.2857142857142857em;
}

.uded6c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.714285714285714em;
  box-sizing: content-box;
  background: #1290c9;
  border-radius: 0em 0.2857142857142857em 0.2857142857142857em 0em;
}

.u3eec5 {
  width: 30em;
  margin-left: 1.4285714285714286em;
}

.u5ieVg3 {
  width: 30em;
}

.urwrm7d {
  width: 100%;
  height: 41.142857142857146em;
  margin-top: 2.4285714285714284em;
  border: 0.14285714285714285em dashed #ccc;
}

.ube6u7R {
  display: flex;
}

.u95125 {
  width: 0;
  flex: auto;
}

.ulFepxe {
  width: 100%;
  height: 41.142857142857146em;
}

.uA9t27G:hover {
  cursor: pointer;
}

.uPvcZd1:hover {
  cursor: pointer;
}

.uSO3fVc:hover {
  cursor: pointer;
}

.uElUXut:hover {
  cursor: pointer;
}

.ecahrtstu {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100% ;

  background: #ffffff;
}

.trendEchartBox1 {
  width: 85.71428571428571em;
  height: 44.57142857142857em;
}

.echartsBtnspiv {
  position: relative;
}

.ulDmLg9 {
  height: 56.857142857142854em;
}

/* edit */
.u2vZuCk {
  padding-top: 2.142857142857143em;
  padding-bottom: 2.5em;
}
@media screen and (max-width: 1441px) {
  .u144f9 {
    font-size: 14px;
  }
}
</style>